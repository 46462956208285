
import Vue from "vue";
import SelectBoxItem from "@/components/molecules/SelectBoxItem.vue";

export default Vue.extend<{}, Methods, {}, Props>({
  components: { SelectBoxItem },
  methods: {
    onSelectItem(item) {
      this.$emit("select", item);
      if (this.onSelect) this.onSelect(item);
    },
  },
  props: {
    isOpen: Boolean,
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    onSelect: Function,
  },
});

interface Methods {
  onSelectItem: (item: any) => void;
}

interface Props {
  isOpen: boolean;
  items: any[];
  onSelect: (item: any) => void;
}
